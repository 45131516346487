// Generated by Framer (58a021c)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Clipboard from "https://framerusercontent.com/modules/Hj20QU19p80mpYsvesiZ/EqB2oGdmJ81urPOd5L66/Clipboard.js";
const ClipboardFonts = getFonts(Clipboard);

const enabledGestures = {rRIJxy5yO: {hover: true}};

const cycleOrder = ["rRIJxy5yO"];

const variantClassNames = {rRIJxy5yO: "framer-v-751f55"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "rRIJxy5yO", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rRIJxy5yO", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-bOaBC", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-751f55", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rRIJxy5yO"} ref={ref} style={{...style}} {...addPropertyOverrides({"rRIJxy5yO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-12lpvt5-container"} layoutDependency={layoutDependency} layoutId={"uq3Ibh9PN-container"}><Clipboard borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} color={"var(--token-883132e0-45d6-4b2b-bb30-29dc404e311e, rgb(29, 29, 31)) /* {\"name\":\"text color\"} */"} content={""} fill={"rgb(255, 255, 255)"} font fontFamily={"Inter"} fontSize={36} fontWeight={400} height={"100%"} id={"uq3Ibh9PN"} isMixedBorderRadius={false} label={"tanjimislam@gmail.com"} layoutId={"uq3Ibh9PN"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} style={{width: "100%"}} topLeftRadius={0} topRightRadius={0} width={"100%"} {...addPropertyOverrides({"rRIJxy5yO-hover": {color: "rgb(255, 255, 255)", fill: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0)) /* {\"name\":\"orange\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-bOaBC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bOaBC .framer-1g4po0o { display: block; }", ".framer-bOaBC .framer-751f55 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 394px; }", ".framer-bOaBC .framer-12lpvt5-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", ".framer-bOaBC .framer-v-751f55 .framer-751f55 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bOaBC .framer-751f55 { gap: 0px; } .framer-bOaBC .framer-751f55 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-bOaBC .framer-751f55 > :first-child { margin-left: 0px; } .framer-bOaBC .framer-751f55 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 394
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"jJOozOdOO":{"layout":["fixed","auto"]}}}
 */
const FramercXTJQU0jb: React.ComponentType<Props> = withCSS(Component, css, "framer-bOaBC") as typeof Component;
export default FramercXTJQU0jb;

FramercXTJQU0jb.displayName = "Mail";

FramercXTJQU0jb.defaultProps = {height: 44, width: 394};

addFonts(FramercXTJQU0jb, [...ClipboardFonts])